import { all, delay, put, takeEvery } from 'redux-saga/effects';
import { AnyAction } from 'redux';
import { HYDRATE } from 'next-redux-wrapper';
import { IState } from '../types';

const unsetDelay = 3000;

export const actionTypes = {
  NOTIFY_SUCCESS: 'NOTIFY_SUCCESS',
  NOTIFY_FAILURE: 'NOTIFY_FAILURE',
  UNSET_BUTTON: 'UNSET_BUTTON',
};

const initialState = {};

/* ACTION CREATORS */

export const notifySuccess = (id: string) => ({
  type: actionTypes.NOTIFY_SUCCESS,
  id,
});

export const notifyFailure = (id: string) => ({
  type: actionTypes.NOTIFY_FAILURE,
  id,
});

export const unsetButton = (id: string) => ({
  type: actionTypes.UNSET_BUTTON,
  id,
});

/* Selectors */

export const succeeded = (id: string) => (state: IState) => state.buttons[id] === 'success';
export const failed = (id: string) => (state: IState) => state.buttons[id] === 'failure';

/* Reducer */

const reducer = (state: Record<string, string> = initialState, action: AnyAction) => {
  switch (action.type) {
    case HYDRATE: {
      return { ...state, ...action.payload.notifications };
    }

    case actionTypes.NOTIFY_SUCCESS: {
      return {
        ...state,
        [action.id]: 'success',
      };
    }

    case actionTypes.NOTIFY_FAILURE: {
      return {
        ...state,
        [action.id]: 'failure',
      };
    }

    case actionTypes.UNSET_BUTTON: {
      const { [action.id]: _value, ...newState } = state;

      return newState;
    }

    default:
      return state;
  }
};

/* Sagas */

export function* handleChange(action: AnyAction) {
  const { id } = action;
  yield delay(unsetDelay);
  yield put(unsetButton(id));
}

export function* rootSaga() {
  yield all([
    takeEvery(actionTypes.NOTIFY_FAILURE as string, handleChange),
    takeEvery(actionTypes.NOTIFY_SUCCESS as string, handleChange),
  ]);
}

export default reducer;
