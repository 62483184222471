import NotificationMolecule from 'components/Notification';
import { actions, INotification, selectors } from 'lib/redux/notifications';
import { useDispatch, useSelector } from 'react-redux';

const { getCurrentNotifications } = selectors;
const { removeNotification } = actions;

const Notifications: React.FC = () => {
  const dispatch = useDispatch();
  const currentNotifications = useSelector(getCurrentNotifications);

  const onClickClose = (id: string) => () => {
    dispatch(removeNotification(id));
  };

  return (
    <>
      {currentNotifications.map((notification: INotification) => (
        <NotificationMolecule
          message={notification.message}
          severity={notification.severity}
          onClickClose={onClickClose(notification.id as string)}
          key={`notification-${notification.id}`}
        />
      ))}
    </>
  );
};

export default Notifications;
