import { call, delay, put, race, select, take } from 'redux-saga/effects';
import { AnyAction } from 'redux';
import { HYDRATE } from 'next-redux-wrapper';
import { v4 as uuidv4 } from 'uuid';
import { IState } from '../types';
import { isBackendError } from './errors';

export const actionTypes = {
  ADD_ERRORS: 'ADD_ERRORS',
  ADD_NOTIFICATIONS: 'ADD_NOTIFICATIONS',
  REMOVE_NOTIFICATION: 'REMOVE_NOTIFICATION',
  CLEAR_NOTIFICATIONS: 'CLEAR_NOTIFICATIONS',
  SHOW_NEXT_NOTIFICATIONS: 'SHOW_NEXT_NOTIFICATIONS',
};

type NotificationSeverity = 'error' | 'success';

export interface INotification {
  severity: NotificationSeverity;
  message: string;
  id?: string;
}

interface INotificationState {
  queue: INotification[];
  currentNotifications: INotification[];
}

const initialState = {
  queue: [],
  currentNotifications: [],
};

/* ACTION CREATORS */

export const addErrors = (errors: any) => ({
  type: actionTypes.ADD_ERRORS,
  errors,
});

export const addNotifications = (notifications: INotification[]) => ({
  type: actionTypes.ADD_NOTIFICATIONS,
  notifications,
});

export const removeNotification = (id: string) => ({
  type: actionTypes.REMOVE_NOTIFICATION,
  id,
});

export const clearNotifications = () => ({
  type: actionTypes.CLEAR_NOTIFICATIONS,
});

export const showNextNotifications = () => ({
  type: actionTypes.SHOW_NEXT_NOTIFICATIONS,
});

/* Selectors */

export const hasNotificationsQueued: (state: IState) => boolean = (state) =>
  !!state.notifications.queue.length;
export const getCurrentNotifications: (state: IState) => INotification[] = (state) =>
  state.notifications.currentNotifications;

/* Reducer */

const formatErrors = (errors: any | any[]) => {
  const e = Array.isArray(errors) ? errors : [errors];

  return e.map((error) => {
    if (isBackendError(error)) {
      console.log(error.graphQLErrors[0]);
      const { errorCode, ...e } = error.graphQLErrors?.[0]?.extensions || {};

      return {
        severity: 'error',
        message: `${errorCode || error.message}

${Object.entries(e).map(([k, v]) => `${k}: ${JSON.stringify(v)}`)}
        `,
      };
    }

    return {
      severity: 'error',
      message: error.message as string,
    };
  });
};

const reducer = (state: INotificationState = initialState, action: AnyAction) => {
  switch (action.type) {
    case HYDRATE: {
      return { ...state, ...action.payload.notifications };
    }

    case actionTypes.ADD_ERRORS: {
      const notifications = formatErrors(action.errors);

      return {
        ...state,
        queue: notifications.map((notification) => ({
          ...notification,
          id: uuidv4(),
        })),
      };
    }

    case actionTypes.ADD_NOTIFICATIONS: {
      return {
        ...state,
        queue: action.notifications.map((notification: INotification) => ({
          ...notification,
          id: uuidv4(),
        })),
      };
    }

    case actionTypes.REMOVE_NOTIFICATION:
      return {
        ...state,
        currentNotifications: state.currentNotifications.filter(
          (notification) => notification.id !== action.id
        ),
      };

    case actionTypes.CLEAR_NOTIFICATIONS:
      return {
        ...state,
        currentNotifications: [],
      };

    case actionTypes.SHOW_NEXT_NOTIFICATIONS:
      return {
        ...state,
        queue: [],
        currentNotifications: state.queue,
      };

    default:
      return state;
  }
};

/* Sagas */

export function* showNotification(): Generator<any, any, any> {
  yield put(showNextNotifications());
  yield delay(10000);
  yield put(clearNotifications());
}

export function* rootSaga(): Generator<any, any, any> {
  while (true) {
    const hasMoreNotificationsQueued = yield select(hasNotificationsQueued);

    if (hasMoreNotificationsQueued) {
      yield race({
        show: call(showNotification),
        new: take(actionTypes.ADD_NOTIFICATIONS),
        remove: take(actionTypes.REMOVE_NOTIFICATION),
      });
    } else {
      yield delay(200);
    }
  }
}

export default reducer;
