import Link, { LinkProps } from 'next/link';
import { useRouter } from 'next/router';
import { forwardRef, ReactNode } from 'react';
import clsx from 'clsx';

type Props = LinkProps & {
  activeClassName: string;
  className?: string;
  children: ReactNode;
};

const NavLink = forwardRef<HTMLAnchorElement, Props>(
  ({ children, className, href, activeClassName }, ref) => {
    const router = useRouter();

    return (
      <Link href={href} passHref>
        <a
          ref={ref}
          className={clsx(className, {
            [activeClassName]:
              href === '/' ? router.pathname === href : router.pathname.startsWith(href.toString()),
          })}
        >
          {children}
        </a>
      </Link>
    );
  }
);

NavLink.displayName = 'NavLink';

export default NavLink;
