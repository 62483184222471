import { createTheme } from '@material-ui/core';
import { ThemeOptions } from '@material-ui/core/styles/createTheme';
import overrides from './overrides';
import palette from './palette';
import typography from './typography';

const baseTheme: ThemeOptions = {
  overrides,
  palette,
  typography,
};

export default createTheme(baseTheme);
