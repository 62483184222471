import { Box, Container, IconButton, makeStyles } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import { Alert } from '@material-ui/lab';
import { useEffect } from 'react';
import toast from 'react-hot-toast';

const useStyles = makeStyles({
  root: {
    'white-space': 'pre-line',
  },
});

interface Props {
  id?: string;
  severity: 'success' | 'info' | 'warning' | 'error';
  onClickClose: () => void;
  message: string;
}

const Notification = ({ id, severity, message, onClickClose }: Props) => {
  const classes = useStyles();

  useEffect(() => {
    if (severity === 'success') toast.success(message);
    if (severity === 'error') toast.error(message);
    if (severity === 'info') toast(message);
  }, [message, severity]);

  return (
    <Box pt={2}>
      <Container maxWidth={false}>
        <Alert
          key={id}
          severity={severity}
          action={
            <IconButton
              aria-label="close"
              color="inherit"
              size="small"
              onClick={() => {
                toast.dismiss();
                onClickClose();
              }}
            >
              <CloseIcon fontSize="inherit" />
            </IconButton>
          }
          className={classes.root}
        >
          {message}
        </Alert>
      </Container>
    </Box>
  );
};

export default Notification;
