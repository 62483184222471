import { all, fork } from 'redux-saga/effects';
import es6promise from 'es6-promise';
import { saga as notificationsSaga } from './notifications';
import { saga as buttonsSaga } from './buttons';
import { saga as pdfSaga } from './pdf';

es6promise.polyfill();

function* rootSaga(): Generator<any, any, any> {
  yield all([fork(notificationsSaga), fork(buttonsSaga), fork(pdfSaga)]);
}

export default rootSaga;
