import { all, call, delay, put, select, takeLatest } from 'redux-saga/effects';
import { AnyAction } from 'redux';
import { HYDRATE } from 'next-redux-wrapper';
import { create as createPdf, retrieve as retrievePdf } from 'lib/pdf';

const loopDelay = 1000;

interface IDocument {
  status: string;
  downloadUrl: string;
  id: string;
  payload: Record<string, string>;
  [key: string]: any;
}

interface IPdfState {
  loading: boolean;
  documents: {
    [uuid: string]: {
      [templateId: string]: IDocument;
    };
  };
}

interface GeneratePdfAction extends AnyAction {
  uuid: string;
  templateId: string;
  payload: Record<string, string>;
}

interface UpdateDocumentAction extends AnyAction {
  uuid: string;
  templateId: string;
  document: Record<string, string>;
}

interface ChangeLoadingAction extends AnyAction {
  loading: boolean;
}

export const actionTypes = {
  GENERATE_PDF: 'GENERATE_PDF',
  UPDATE_DOCUMENT: 'UPDATE_DOCUMENT',
  CHANGE_LOADING: 'CHANGE_LOADING',
};

const initialState: IPdfState = { loading: false, documents: {} };

/* ACTION CREATORS */

export const generatePdf: (
  uuid: string,
  templateId: string,
  payload: Record<string, string>,
  fileName: string
) => GeneratePdfAction = (uuid, templateId, payload, fileName) => ({
  type: actionTypes.GENERATE_PDF,
  uuid,
  templateId,
  payload,
  fileName,
});

export const updateDocument: (
  uuid: string,
  templateId: string,
  document: Record<string, string>
) => UpdateDocumentAction = (uuid, templateId, document) => ({
  type: actionTypes.UPDATE_DOCUMENT,
  uuid,
  templateId,
  document,
});

export const changeLoading: (loading: boolean) => ChangeLoadingAction = (loading) => ({
  type: actionTypes.CHANGE_LOADING,
  loading,
});

/* Selectors */

export const getDocument: (
  uuid: string,
  templateId: string
) => (state: { pdf: IPdfState }) => IDocument | null = (uuid, templateId) => (state) => {
  return state.pdf.documents[uuid]?.[templateId];
};

export const getLoading: () => (state: { pdf: IPdfState }) => boolean = () => (state) => {
  return state.pdf.loading;
};

/* Reducer */

const reducer: (state: IPdfState, action: AnyAction) => IPdfState = (
  state = initialState,
  action
) => {
  switch (action.type) {
    case HYDRATE: {
      return { ...state, ...action.payload.pdf };
    }

    case actionTypes.UPDATE_DOCUMENT: {
      return {
        ...state,
        documents: {
          ...state.documents,
          [action.uuid]: { [action.templateId]: action.document },
        },
      };
    }

    case actionTypes.CHANGE_LOADING: {
      return {
        ...state,
        loading: action.loading,
      };
    }

    default:
      return state;
  }
};

/* Sagas */

export function* handleFetchDownloadUrl(
  uuid: string,
  templateId: string
): Generator<any, any, any> {
  let document = yield select(getDocument(uuid, templateId));

  while (!!document?.id && !document?.downloadUrl) {
    const res = yield call(retrievePdf, document.id);
    const newDocument = yield call([res, 'json']);
    yield put(updateDocument(uuid, templateId, newDocument));
    yield delay(loopDelay);
    document = yield select(getDocument(uuid, templateId));
  }

  yield put(changeLoading(false));
}

export function* handleCreatePdf(
  uuid: string,
  templateId: string,
  payload: Record<string, string>,
  fileName: string
): Generator<any, any, any> {
  const res = yield call(createPdf, templateId, payload, fileName);
  const document = yield call([res, 'json']);
  yield put(updateDocument(uuid, templateId, document));
  yield call(handleFetchDownloadUrl, uuid, templateId);
}

export function* handleGeneratePdf(action: GeneratePdfAction): Generator<any, any, any> {
  const { uuid, templateId, payload, fileName } = action;
  const document = yield select(getDocument(uuid, templateId));

  yield put(changeLoading(true));

  if (!document) {
    yield call(handleCreatePdf, uuid, templateId, payload, fileName);
  } else if (!document.downloadUrl) {
    yield call(handleFetchDownloadUrl, uuid, templateId);
  }
}

export function* rootSaga(): Generator<any, any, any> {
  yield all([takeLatest(actionTypes.GENERATE_PDF as string, handleGeneratePdf)]);
}

export default reducer;
