import { List } from '@material-ui/core';
import NavItem, { NavItemProps } from './NavItem';

type Props = {
  items: Array<{
    subheader: string;
    subitems: NavItemProps[];
  }>;
};

const NavContainer: React.FC<Props> = ({ items }) => {
  return (
    <nav>
      {items.map(
        ({ subheader, subitems }): JSX.Element => (
          <List component="div" key={subheader}>
            {subitems.map(
              (item: NavItemProps): JSX.Element => (
                <NavItem href={item.href} icon={item.icon} title={item.title} key={item.href} />
              )
            )}
          </List>
        )
      )}
    </nav>
  );
};

export default NavContainer;
