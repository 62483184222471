import { colors } from '@material-ui/core';
import { PaletteOptions } from '@material-ui/core/styles/createPalette';

const white = '#ffffff';
const primary = colors.indigo[400];
const secondary = '#ff5b60';
const success = '#72c89e';
const error = '#f26b5b';

const palette: PaletteOptions = {
  background: {
    default: '#f4f6f8',
    paper: white,
  },
  primary: {
    main: primary,
  },
  secondary: {
    main: secondary,
  },
  success: {
    main: success,
    contrastText: white,
  },
  error: {
    main: error,
    contrastText: white,
  },
  text: {
    primary: colors.blueGrey[900],
    secondary: colors.blueGrey[600],
  },
  divider: colors.grey[200],
};

export default palette;
