import { combineReducers } from 'redux';
import { reducer as notificationsReducer } from './notifications';
import { reducer as buttonsReducer } from './buttons';
import { reducer as pdfReducer } from './pdf';

export default combineReducers({
  notifications: notificationsReducer,
  buttons: buttonsReducer,
  pdf: pdfReducer,
});
