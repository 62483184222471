import palette from '../palette';

export default {
  root: {
    borderBottom: `1px solid ${palette.divider}`,
    // This is annoying not to be able to use the spacing
    // defined by the theme... like theme.spacing(1, 2)...
    padding: '12px 16px',
  },
};
