import palette from '../palette';
import { SimplePaletteColorOptions } from '@material-ui/core';

export default {
  root: {
    color: palette.text,
    '&:hover': {
      backgroundColor: 'rgba(208, 208, 208, 0.20)',
    },
    '&$selected': {
      backgroundColor: 'rgba(208, 208, 208, 0.20)',
      color: (palette.primary as SimplePaletteColorOptions).main,
      '&:hover': {
        backgroundColor: 'rgba(208, 208, 208, 0.30)',
      },
    },
    '&:first-child': {
      borderTopLeftRadius: 4,
      borderBottomLeftRadius: 4,
    },
    '&:last-child': {
      borderTopRightRadius: 4,
      borderBottomRightRadius: 4,
    },
  },
};
