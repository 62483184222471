export const create: (
  templateId: string,
  payload: Record<string, string>,
  fileName: string
) => Promise<any> = (templateId, payload, fileName) => {
  return fetch('/api/pdf', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json;charset=utf-8',
    },
    body: JSON.stringify({ payload, templateId, fileName }),
  });
};

export const retrieve: (documentId: string) => Promise<any> = (documentId) => {
  return fetch(`/api/pdf/${documentId}`);
};
