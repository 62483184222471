import {
  AttachMoneyOutlined,
  Event,
  ConfirmationNumber,
  PlaceOutlined,
  PeopleOutlined,
  LoyaltyOutlined,
  HomeWorkOutlined,
  DashboardOutlined,
  CardGiftcard,
} from '@material-ui/icons';

const config = [
  {
    subheader: 'Reports',
    subitems: [
      {
        title: 'Dashboard',
        href: '/',
        icon: DashboardOutlined,
      },
    ],
  },
  {
    subheader: 'Accounts',
    subitems: [
      {
        title: 'Users',
        href: '/users',
        icon: PeopleOutlined,
      },
    ],
  },
  {
    subheader: 'Listings',
    subitems: [
      {
        title: 'Accommodations',
        href: '/rooms',
        icon: HomeWorkOutlined,
      },
      {
        title: 'Places',
        href: '/places',
        icon: PlaceOutlined,
      },
    ],
  },
  {
    subheader: 'Rentals',
    subitems: [
      {
        title: 'Bookings',
        href: '/bookings',
        icon: AttachMoneyOutlined,
      },
    ],
  },
  {
    subheader: 'Communities',
    subitems: [
      {
        title: 'Recommendations',
        href: '/recommendations',
        icon: LoyaltyOutlined,
      },
    ],
  },
  {
    subheader: 'Activities',
    subitems: [
      {
        title: 'Activities',
        href: '/activities',
        icon: Event,
      },
    ],
  },
  {
    subheader: 'Club Subscriptions',
    subitems: [
      {
        title: 'Club Subscriptions',
        href: '/club/subscriptions',
        icon: ConfirmationNumber,
      },
    ],
  },
  {
    subheader: 'Club Gifts Cards',
    subitems: [
      {
        title: 'Club Gifts Cards',
        href: '/club/gifts-cards',
        icon: CardGiftcard,
      },
    ],
  },
];

export default config;
