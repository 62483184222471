import { Box, makeStyles } from '@material-ui/core';
import Notifications from 'components/Notifications';
import withoutSSR from 'components/withoutSSR';
import { useMeQuery } from 'generated/graphql';
import { useState } from 'react';
import NavBar from './NavBar';
import TopBar from './TopBar';

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.default,
    display: 'flex',
    height: '100%',
    overflow: 'hidden',
    width: '100%',
  },
  wrapper: {
    backgroundColor: (theme.palette.background as any).dark,
    display: 'flex',
    flex: '1 1 auto',
    overflow: 'hidden',
    paddingTop: 64,
  },
  contentContainer: {
    display: 'flex',
    flex: '1 1 auto',
    overflow: 'hidden',
  },
  content: {
    flex: '1 1 auto',
    height: '100%',
    overflow: 'auto',
  },
}));

type Props = {
  children: React.ReactNode;
};

const Layout = ({ children }: Props) => {
  const classes = useStyles();
  const [openNavBar, setOpenNavBar] = useState(false);

  const handleDrawerOpen = () => {
    setOpenNavBar(true);
  };

  const handleDrawerClose = () => {
    setOpenNavBar(false);
  };

  const { data } = useMeQuery();

  const authenticated = !!data?.me;

  return (
    <main className={classes.root}>
      <TopBar
        openNavBar={openNavBar}
        onOpenNavBar={handleDrawerOpen}
        authenticated={authenticated}
      />
      {authenticated && <NavBar openNavBar={openNavBar} onCloseNavBar={handleDrawerClose} />}

      <Box className={classes.wrapper}>
        <Box className={classes.contentContainer}>
          <Box className={classes.content}>
            <Notifications />
            {children}
          </Box>
        </Box>
      </Box>
    </main>
  );
};

// @ts-expect-error
export default withoutSSR(Layout);
