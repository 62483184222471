import { ApolloProvider } from '@apollo/client';
import { relayStylePagination } from '@apollo/client/utilities';
import { ThemeProvider } from '@material-ui/core';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import { IntlProvider } from 'react-intl';
import GlobalStyles from 'components/GlobalStyles';
import Layout from 'components/Layout';
import { useApollo } from 'lib/apollo';
import { SagaStore, wrapper } from 'lib/redux/store';
import { AppContext } from 'next/app';
import PropTypes from 'prop-types';
import { useEffect } from 'react';
import { Helmet } from 'react-helmet';
import { END } from 'redux-saga';
import theme from 'theme';
import { NextPageContext } from 'next';
import { Toaster } from 'react-hot-toast';

import btoa from 'btoa';
import atob from 'atob';

global.atob = atob;
global.btoa = btoa;

const MyApp = (props: any) => {
  const { Component, pageProps } = props;
  const apolloClient = useApollo(pageProps.initialApolloState, {
    cacheOpts: {
      typePolicies: {
        Query: {
          fields: {
            comments: relayStylePagination(),
          },
        },
      },
    },
  });

  useEffect(() => {
    // Remove the server-side injected CSS.
    const jssStyles = document.querySelector('#jss-server-side');
    if (jssStyles) {
      jssStyles!.parentElement!.removeChild(jssStyles);
    }
  }, []);

  return (
    <IntlProvider locale="fr" defaultLocale="fr">
      <Toaster
        position="top-right"
        toastOptions={{
          style: { fontFamily: 'Roboto' },
          error: { style: { background: '#dc2626', color: '#fff' } },
        }}
      />
      <Helmet
        htmlAttributes={{ lang: 'en' }}
        title="Colette Admin"
        meta={[
          {
            name: 'viewport',
            content: 'width=device-width, initial-scale=1',
          },
          { property: 'og:title', content: 'Hello next.js!' },
        ]}
      />
      <ThemeProvider theme={theme}>
        <MuiPickersUtilsProvider utils={DateFnsUtils}>
          <GlobalStyles />
          <ApolloProvider client={apolloClient}>
            <Layout>
              {/* eslint-disable-next-line react/jsx-props-no-spreading */}
              <Component {...pageProps} />
            </Layout>
          </ApolloProvider>
        </MuiPickersUtilsProvider>
      </ThemeProvider>
    </IntlProvider>
  );
};

type NextPageContextWithStore = NextPageContext & {
  store: SagaStore;
};

export const getInitialProps: (context: AppContext) => Record<string, any> = async ({
  Component,
  ctx,
}) => {
  const pageProps = {
    ...(Component.getInitialProps ? await Component.getInitialProps(ctx) : {}),
  };

  // Stop the saga if on server
  if (ctx.req) {
    (ctx as NextPageContextWithStore).store.dispatch(END);
    await ((ctx as NextPageContextWithStore).store as SagaStore).sagaTask!.toPromise();
  }

  return { pageProps };
};

MyApp.propTypes = {
  Component: PropTypes.elementType.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  pageProps: PropTypes.object.isRequired,
};

export default wrapper.withRedux(MyApp);
