import { Box, Divider, Drawer, IconButton, makeStyles, Theme, useTheme } from '@material-ui/core';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import clsx from 'clsx';
import config from './config';
import NavContainer from './NavContainer';

const drawerWidth = 240;
const useStyles = makeStyles((theme: Theme) => ({
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
    whiteSpace: 'nowrap',
  },
  drawerOpen: {
    width: drawerWidth,
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  drawerClose: {
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    overflowX: 'hidden',
    width: theme.spacing(5),
    [theme.breakpoints.up('sm')]: {
      width: theme.spacing(7) + 1,
    },
  },
  toolbar: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
  },
}));

type Props = {
  openNavBar: boolean;
  onCloseNavBar: () => void;
};

const NavBar: React.FC<Props> = ({ openNavBar, onCloseNavBar }) => {
  const classes = useStyles();
  const theme = useTheme();
  const items = config;

  return (
    <Drawer
      variant="permanent"
      className={clsx(classes.drawer, {
        [classes.drawerOpen]: openNavBar,
        [classes.drawerClose]: !openNavBar,
      })}
      classes={{
        paper: clsx({
          [classes.drawerOpen]: openNavBar,
          [classes.drawerClose]: !openNavBar,
        }),
      }}
    >
      <div className={classes.toolbar}>
        <IconButton onClick={onCloseNavBar}>
          {theme.direction === 'rtl' ? <ChevronRightIcon /> : <ChevronLeftIcon />}
        </IconButton>
      </div>
      <Divider />
      <Box height="100%" display="flex" flexDirection="column">
        <NavContainer items={items} />
      </Box>
    </Drawer>
  );
};

export default NavBar;
