import { AppBar, IconButton, makeStyles, Theme, Toolbar } from '@material-ui/core';
import MenuIcon from '@material-ui/icons/Menu';
import clsx from 'clsx';
import BrandLogo from 'components/BrandLogo';
import Link from 'next/link';

const drawerWidth = 240;
const useStyles = makeStyles((theme: Theme) => ({
  root: {
    zIndex: theme.zIndex.drawer + 100,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    boxShadow: 'none',
    backgroundColor: theme.palette.primary.main,
  },
  shift: {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  toolbar: {
    minHeight: 64,
  },
  noDecorationA: {
    cursor: 'pointer',
    textDecoration: 'none',
    color: '#ffffff',
  },
  menuButton: {
    marginRight: 36,
  },
  hide: {
    display: 'none',
  },
}));

type Props = {
  href?: string;
  openNavBar: boolean;
  onOpenNavBar: () => void;
  authenticated: boolean;
};

const TopBar: React.FC<Props> = ({ href, onOpenNavBar, openNavBar, authenticated }) => {
  const classes = useStyles();

  return (
    <AppBar
      position="fixed"
      className={clsx(classes.root, {
        [classes.shift]: openNavBar,
      })}
      elevation={0}
    >
      <Toolbar className={classes.toolbar}>
        {authenticated && (
          <IconButton
            aria-label="open drawer"
            color="inherit"
            onClick={onOpenNavBar}
            edge="start"
            className={clsx(classes.menuButton, {
              [classes.hide]: openNavBar,
            })}
          >
            <MenuIcon />
          </IconButton>
        )}

        <Link href={href || '/'} passHref>
          <a className={classes.noDecorationA}>
            <BrandLogo />
          </a>
        </Link>
      </Toolbar>
    </AppBar>
  );
};

export default TopBar;
