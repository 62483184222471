import palette from '../palette';
import { SimplePaletteColorOptions } from '@material-ui/core';

export default {
  root: {
    borderRadius: 0,
    overflow: 'hidden',
  },
  colorPrimary: {
    backgroundColor: (palette.primary as SimplePaletteColorOptions).main,
  },
};
