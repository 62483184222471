type BackendErrors =
  | 'AcceptBookingStageError'
  | 'ArchivedRoomError'
  | 'ArgumentError'
  | 'BannedError'
  | 'CancelBookingStageError'
  | 'ContractGenerationError'
  | 'DepositAlreadyPaidError'
  | 'EmailAlreadyConfirmedError'
  | 'EmailAlreadyTakenError'
  | 'GuestAlreadyHouseSharingError'
  | 'GuestOnlyError'
  | 'HostHasNoRoomsError'
  | 'HostOnlyError'
  | 'InvalidCredentialsError'
  | 'InvalidEmailFormatError'
  | 'InvalidHoldEntryError'
  | 'InvalidInvoiceError'
  | 'InvalidPaymentError'
  | 'InvalidPhoneNumberFormatError'
  | 'InvalidTokenError'
  | 'InvalidTransferError'
  | 'NoPaymentAccountError'
  | 'PasswordAlreadyChosenError'
  | 'PasswordAlreadySetError'
  | 'PaymentError'
  | 'RejectBookingStageError'
  | 'RejectedUserError'
  | 'RemoteServiceError'
  | 'RoomBookedError'
  | 'RoomNotAvailableError'
  | 'UnauthenticatedError'
  | 'UnhandledError'
  | 'UserHasNoNewEmailError';

export interface BackendError extends Error {
  graphQLErrors: {
    message: string;
    locations?: { column: number; line: number }[];
    path?: string[];
    error: string;
    extensions:
      | {
          errorCode: BackendErrors;
        }
      | {
          errorCode: 'UnauthorizedError';
          reason: string;
        }
      | {
          errorCode: 'NotFoundError';
          fields: string[];
          type: string; // User, Booking, Room
        }
      | {
          errorCode: 'ValidationError';
          field: string;
          reasons: string[];
        };
  }[];
}

export const isBackendError = (error: any | BackendError): error is BackendError =>
  'graphQLErrors' in error;
