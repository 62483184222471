import { ApolloClient, ApolloLink, HttpLink, InMemoryCache } from '@apollo/client';
import { NormalizedCacheObject } from '@apollo/client/cache';
import { useMemo } from 'react';
import { onError } from '@apollo/client/link/error';
import omitDeep from 'omit-deep-lodash';

const cleanTypenameLink = new ApolloLink((operation, forward) => {
  if (operation.variables && !operation.variables.file) {
    // eslint-disable-next-line
    operation.variables = omitDeep(operation.variables, '__typename');
  }

  return forward(operation);
});

let apolloClient: ApolloClient<NormalizedCacheObject> | undefined;

const defaultApiUrl = process.env.NEXT_PUBLIC_PROXY_URL;

export interface IApolloClientOptions {
  headers?: any;
  apiUrl?: string;
  cacheOpts?: any;
}

const createApolloClient = (opts: IApolloClientOptions = {}) => {
  const { headers, apiUrl, cacheOpts } = opts;

  const httpLink = new HttpLink({
    uri: apiUrl || defaultApiUrl,
    credentials: 'same-origin',
    fetch,
    headers,
  });

  return new ApolloClient({
    ssrMode: typeof window === 'undefined',
    link: ApolloLink.from([cleanTypenameLink, httpLink]),
    cache: new InMemoryCache(cacheOpts),
  });
};

export const initializeApollo: (
  initialState: NormalizedCacheObject | null,
  opts: IApolloClientOptions
) => ApolloClient<NormalizedCacheObject> = (initialState = null, opts = {}) => {
  // eslint-disable-next-line no-underscore-dangle
  const _apolloClient = apolloClient ?? createApolloClient(opts);

  // If your page has Next.js data fetching methods that use Apollo Client, the initial state
  // gets hydrated here
  if (initialState) {
    _apolloClient.cache.restore(initialState);
  }
  // For SSG and SSR always create a new Apollo Client
  if (typeof window === 'undefined') return _apolloClient;
  // Create the Apollo Client once in the client
  if (!apolloClient) apolloClient = _apolloClient;

  return _apolloClient;
};

export const useApollo: (
  initialState: NormalizedCacheObject | null,
  opts: IApolloClientOptions
) => ApolloClient<NormalizedCacheObject> = (initialState = null, opts = {}) =>
  useMemo(() => initializeApollo(initialState, opts), [initialState, opts]);
