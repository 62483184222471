import {
  ListItem,
  ListItemIcon,
  ListItemText,
  makeStyles,
  SvgIcon,
  Theme,
} from '@material-ui/core';
import NavLink from './NavLink';

const useStyles = makeStyles((theme: Theme) => ({
  button: {
    color: theme.palette.text.secondary,
    textTransform: 'none',
    minWidth: theme.spacing(10),
  },
  gutters: {
    padding: theme.spacing(1, 1),
    [theme.breakpoints.up('sm')]: {
      padding: theme.spacing(1, 2),
    },
  },
  icon: {
    display: 'flex',
    alignItems: 'center',
    marginRight: theme.spacing(1),
  },
  active: {
    color: theme.palette.primary.main,
    '& .MuiListItemText-root': {
      fontWeight: theme.typography.fontWeightMedium,
    },
    '& .MuiListItemIcon-root': {
      color: theme.palette.primary.main,
    },
  },
}));

export type NavItemProps = {
  href: string;
  icon: typeof SvgIcon;
  title: string;
};

const NavItem: React.FC<NavItemProps> = ({ href, icon, title }) => {
  const classes = useStyles();
  const Icon = icon;

  return (
    <ListItem
      classes={{ gutters: classes.gutters }}
      button
      activeClassName={classes.active}
      className={classes.button}
      href={href}
      component={NavLink}
    >
      {Icon && (
        <ListItemIcon>
          <Icon className={classes.icon} />
        </ListItemIcon>
      )}
      <ListItemText primary={title} />
    </ListItem>
  );
};

export default NavItem;
